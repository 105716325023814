<!--
	Last modified: 2022/02/22 18:16:32
-->
<template>
	<div
		:class="[
			'c-video-player',
			`c-video-player--is-${picker.provider.alias}`,
		]"
		v-on="$listeners"
	>
		<Component
			:is="getPlayerComponent(picker.provider.alias)"
			v-if="picker && isEmbedded"
			ref="player"
			class="c-video-player__player"
			:class="playerClassNames"
			:style="playerStyles"
			v-bind="$attrs"
			:data="picker"
		/>
		<div v-if="$scopedSlots.overlay" class="c-video-player__overlay">
			<slot v-bind="slotProps" name="overlay"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VideoPlayer',

	components: {
		Html5: () => import('./VideoPlayerHtml5.vue'),
		Vimeo: () => import('./VideoPlayerVimeo.vue'),
		DreamBroker: () => import('./VideoPlayerDreamBroker.vue'),
		Youtube: () => import('./VideoPlayerYoutube.vue'),
	},

	inheritAttrs: false,

	props: {
		picker: {
			type: Object,
			required: true,
		},

		playerClassNames: {
			type: [Array, Object, String],
			default: '',
		},

		playerStyles: {
			type: [Array, Object, String],
			default: '',
		},

		embedOnPlay: {
			type: Boolean,
			default: false,
		},

		useHtml5Player: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			states: {
				showOverlay: true,
				playback: null,
			},

			isEmbedded: !this.embedOnPlay,
			queuedEmbedPlay: null,
		};
	},

	computed: {
		defaultPoster() {
			return (
				this.$attrs.poster ||
				this.picker?.details?.thumbnails.slice(-1)?.[0]?.url ||
				''
			);
		},

		slotProps() {
			return {
				overlay: {
					hide: this.hideOverlay,
					show: this.showOverlay,
					isVisible: this.states.showOverlay,
				},

				service: {
					poster: this.defaultPoster,
					thumbnails: this.picker?.details?.thumbnails || [],

					state: this.states.playback,
					provider: this.useHtml5Player
						? 'html5'
						: this.picker?.provider?.alias || undefined,

					play: this.play,
					pause: this.pause,
					stop: this.stop,
				},

				misc: {
					isEmbedded: this.isEmbedded,
					embed: this.embed,
					unembed: this.unembed,
				},
			};
		},
	},

	methods: {
		embed() {
			this.isEmbedded = true;
		},
		unembed() {
			this.isEmbedded = false;
			this.states.playback = null;
		},

		showOverlay(callback) {
			this.$set(this.states, 'showOverlay', true);

			if (callback && typeof callback === 'function') {
				callback();
			}
		},
		hideOverlay(callback) {
			this.$set(this.states, 'showOverlay', false);

			if (callback && typeof callback === 'function') {
				callback();
			}
		},

		play(callback) {
			if (!this.isEmbedded && this.embedOnPlay) {
				this.embed();
				this.queuedEmbedPlay = () => {
					this.play(callback);
				};
				return;
			}

			if (this.$refs.player?.play) {
				this.$refs.player.play();
			} else if (process.env.NODE_ENV === 'development') {
				console.warn(
					`VideoPlayer [${this.picker.provider.alias}]: no PLAY method available`
				);
			}

			if (callback && typeof callback === 'function') {
				callback();
			}
		},
		pause(callback) {
			if (this.$refs.player?.pause) {
				this.$refs.player.pause();
			} else if (process.env.NODE_ENV === 'development') {
				console.warn(
					`VideoPlayer [${this.picker.provider.alias}]: no PAUSE method available`
				);
			}

			if (callback && typeof callback === 'function') {
				callback();
			}
		},
		stop(callback) {
			if (this.$refs.player?.stop) {
				this.$refs.player.stop();
			} else if (process.env.NODE_ENV === 'development') {
				console.warn(
					`VideoPlayer [${this.picker.provider.alias}]: no STOP method available`
				);
			}

			if (callback && typeof callback === 'function') {
				callback();
			}
		},

		getPlayerComponent(string) {
			if (this.useHtml5Player || !string) {
				return 'Html5';
			}

			const providers = {
				html5: 'Html5',
				dreambroker: 'DreamBroker',
				youtube: 'Youtube',
				vimeo: 'Vimeo',
				// jwplayer: 'JwPlayer',
			};

			return providers[string];
		},
		emit(payload, type) {
			this.states.playback = type;
			this.$emit(`is-${type}`, payload);

			// When embed-on-play is enabled, we need to wait for the player to be embedded
			if (
				this.queuedEmbedPlay &&
				['ready', 'embedded', 'canplay'].includes(type)
			) {
				this.queuedEmbedPlay();
				this.queuedEmbedPlay = null;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-video-player {
	position: relative;
	width: 100%;

	& > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.c-video-player__overlay {
	pointer-events: none;
}

:where(.c-video-player__overlay) > * {
	pointer-events: all;
}
</style>
