var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.text || _vm.video)?_c('div',{class:[
		'c-block-list-video-custom',
		'w-5col >=768:w-8col max-w-full mx-auto',
	],attrs:{"id":_vm.$vnode.key}},[(_vm.title && !_vm.accordionLevel && _vm.includeInToc)?_c('LongReadTarget',{attrs:{"container-id":_vm.$vnode.key,"title":_vm.title}}):_vm._e(),_vm._v(" "),(_vm.title)?_c('BaseH2',{staticClass:"mb-lg",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.picker)?_c('div',{class:[
			'c-block-list-video-custom__content',
			'relative w-full h-0 overflow-hidden',
		],style:({ 'padding-top': `${_vm.aspectRatio * 100}%` })},[_c('VideoPlayer',{ref:"videoPlayer",staticClass:"absolute top-0 left-0 w-full h-full",attrs:{"picker":_vm.picker,"embed-on-play":true},scopedSlots:_vm._u([{key:"overlay",fn:function({ overlay, service }){return [_c('button',{class:[
						'relative w-full h-full',
						'duration-300 delay-200 ease-smooth-out',
						'group',

						{
							'opacity-0 pointer-events-none':
								!overlay.isVisible,
						},
					],attrs:{"aria-label":"Afspil video"},on:{"click":function($event){$event.preventDefault();return (() => {
							service.play(overlay.hide);
							_vm.loading = true;
						}).apply(null, arguments)}}},[_c('img',{class:[
							'absolute top-0 left-0',
							'w-full h-full object-cover',
						],attrs:{"src":service.poster}}),_vm._v(" "),_c('div',{class:[
							'absolute top-0 left-0',
							'w-full h-full bg-black bg-opacity-50',
							'duration-300 ease-smooth-out',
							{ 'group-hover:opacity-95': !_vm.loading },
						]}),_vm._v(" "),_c('div',{class:[
							'c-block-list-video-custom__button',
							'absolute top-1/2 left-1/2',
							'transform -translate-x-1/2 -translate-y-1/2',
							'rounded-full bg-primary',
							'flex justify-center items-center',
							'w-7xl h-7xl',

							{
								'opacity-80': _vm.loading,
								'group-hover:scale-105': !_vm.loading,
								'scale-85 opacity-0': !overlay.isVisible,
							},
						]},[_c('span',{staticClass:"font-darker-grotesque text-video-play c-block-list-video-custom__play-button flex items-center justify-center uppercase mb-4 >=1024:mb-6"},[_vm._v("\n\t\t\t\t\t\t\tAfspil\n\t\t\t\t\t\t")])])])]}}],null,false,635647920)})],1):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"text-text s-rich-text mt-md",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }