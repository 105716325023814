<template>
	<div
		v-if="title || text || video"
		:id="$vnode.key"
		:class="[
			'c-block-list-video-custom',
			'w-5col >=768:w-8col max-w-full mx-auto',
		]"
	>
		<LongReadTarget
			v-if="title && !accordionLevel && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" class="mb-lg" v-text="title" />

		<div
			v-if="picker"
			:class="[
				'c-block-list-video-custom__content',
				'relative w-full h-0 overflow-hidden',
			]"
			:style="{ 'padding-top': `${aspectRatio * 100}%` }"
		>
			<VideoPlayer
				ref="videoPlayer"
				:picker="picker"
				:embed-on-play="true"
				class="absolute top-0 left-0 w-full h-full"
			>
				<template #overlay="{ overlay, service }">
					<button
						:class="[
							'relative w-full h-full',
							'duration-300 delay-200 ease-smooth-out',
							'group',

							{
								'opacity-0 pointer-events-none':
									!overlay.isVisible,
							},
						]"
						aria-label="Afspil video"
						@click.prevent="
							() => {
								service.play(overlay.hide);
								loading = true;
							}
						"
					>
						<img
							:src="service.poster"
							:class="[
								'absolute top-0 left-0',
								'w-full h-full object-cover',
							]"
						/>

						<div
							:class="[
								'absolute top-0 left-0',
								'w-full h-full bg-black bg-opacity-50',
								'duration-300 ease-smooth-out',
								{ 'group-hover:opacity-95': !loading },
							]"
						></div>

						<div
							:class="[
								'c-block-list-video-custom__button',
								'absolute top-1/2 left-1/2',
								'transform -translate-x-1/2 -translate-y-1/2',
								'rounded-full bg-primary',
								'flex justify-center items-center',
								'w-7xl h-7xl',

								{
									'opacity-80': loading,
									'group-hover:scale-105': !loading,
									'scale-85 opacity-0': !overlay.isVisible,
								},
							]"
						>
							<span
								class="
									font-darker-grotesque
									text-video-play
									c-block-list-video-custom__play-button
									flex
									items-center
									justify-center
									uppercase
									mb-4
									>=1024:mb-6
								"
							>
								Afspil
							</span>
						</div>
					</button>
				</template>
			</VideoPlayer>
		</div>

		<div
			v-if="text"
			class="text-text s-rich-text mt-md"
			v-html="text"
		></div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import { VideoPlayer } from '~/citi-baseline/components/VideoPlayer';

export default {
	name: 'BlockListVideoCustom',
	components: { LongReadTarget, VideoPlayer },

	inject: {
		accordionLevel: {
			default: 0,
		},
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		includeInToc: {
			type: Boolean,
			required: false,
		},
		text: {
			type: String,
			required: false,
		},
		video: {
			type: Object,
			required: false,
		},
	},

	data() {
		return {
			loading: false,
		};
	},
	computed: {
		picker() {
			return this.video?.content?.video
				? {
						details: {
							...(this.video?.content?.video.details ?? {}),
							embed: this.video?.content?.video?.embed?.html,
						},
						provider: {
							alias: this.video?.alias
								?.replace('Block', '')
								.toLowerCase(),
						},
				  }
				: false;
		},
		aspectRatio() {
			const html = this.video?.content?.video?.embed?.html;

			if (html) {
				const width = html.match(/width="([0-9]*)"/)[1];
				const height = html.match(/height="([0-9]*)"/)[1];

				if (width && height) {
					return parseInt(height) / parseInt(width);
				}
			}

			return 9 / 16;
		},
	},
	methods: {
		toggleVideo() {
			if (this.$refs?.videoPlayer) {
				const { videoPlayer } = this.$refs;
				const { playback } = videoPlayer.states;

				videoPlayer[playback ? 'pause' : 'play']();
				videoPlayer[playback ? 'hideOverlay' : 'showOverlay']();
				this.playing = !playback;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-video-custom__play-button {
	min-width: 102px;
	min-height: 102px;
	width: 9.59vw;
	height: 9.59vw;
	max-width: 138px;
	max-height: 138px;
}

.c-block-list-video-custom__button {
	transition-property: opacity, transform, background-color;
	transition-timing-function: cubic-bezier(0.5, 0.035, 0.19, 1),
		cubic-bezier(0.5, 0.035, 0.19, 1), cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 300ms, 300ms, var(--scoped-color-theme-duration, 0s);
}
</style>
